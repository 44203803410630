<template>
  <div>
    <div class="page-title">重置密码</div>
    <div class="box">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
      >
        <div>
          <span class="xinghao">*</span>
          <el-form-item prop="username">
            <el-input placeholder="请输入账号" v-model="form.username" clearable></el-input>
          </el-form-item>
          <div class="clear"/>
        </div>
        <div>
          <span class="xinghao">*</span>
          <el-form-item prop="password">
            <el-input placeholder="请输入新密码" v-model="form.password" clearable show-password></el-input>
          </el-form-item>
          <div class="clear"/>
        </div>
        <div>
          <span class="xinghao">*</span>
          <el-form-item prop="repassword">
            <el-input placeholder="请再次输入新密码" v-model="form.repassword" clearable show-password></el-input>
          </el-form-item>
          <div class="clear"/>
        </div>
        <div>
          <span class="xinghao">*</span>
          <el-form-item prop="telephone">
            <el-button class="getCodeBtn" v-on:click="getPhoneCode" :disabled="disabled" :loading="phoneLoading" >{{ sendTips }}</el-button>
            <el-input class="phoneInput" placeholder="请输入与帐号绑定的手机号码" v-model="form.telephone"></el-input>
          </el-form-item>
          <div class="clear"/>
        </div>
        <div>
          <span class="xinghao">*</span>
          <el-form-item prop="valicode">
            <el-input placeholder="请输入手机验证码" v-model="form.valicode"></el-input>
          </el-form-item>
          <div class="clear"/>
        </div>
        <div>
          <span class="xinghao">*</span>
          <div style="display:flex;float:right">
            <div>
              <el-input type="text" id="code" v-model="code" class="code"  placeholder="请输入验证码" />
            </div>
            <div class="login-code" @click="refreshCode">
              <!--验证码组件-->
              <s-identify :identifyCode="identifyCode"></s-identify>
            </div>
            <div class="clear"/>
          </div>
          <div class="clear"/>
        </div>
        <div style="text-align:center;margin:20px auto">
          <router-link class="login-button" :to="{ name: 'signin' }">
            <el-button size="medium" type="primary">返回登录</el-button>
          </router-link>
          <el-button size="medium" type="primary" :loading="subLoading" @click="handleUpdate">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  isPoneAvailable
} from '@/utils/utils'
import { setCookie, getCookie, removeCookie } from '@/utils/auth'
import { modifyPassword, sendValidateCode } from '@/api'
import SIdentify from '@/components/sidentify'
export default {
  components: {
    SIdentify
  },
  props: {},
  data () {
    const validatePass1 = (rule, value, callback) => {
      let regNumber = /\d+/; //验证0-9的任意数字最少出现1次。
      let regString = /[a-zA-Z]+/; //验证大小写26个字母任意字母最少出现1次。
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value.length < 8) {
        callback(new Error('密码不得少于8位'))
      } else if (!regNumber.test(value) || !regString.test(value)) {
        callback(new Error('密码必须包含数字和英文'))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validatePhone = (rule, value, callback) => {
      let reg = /^1(3[0-9]|4[5,7]|5[0,1,2,3,5,6,7,8,9]|6[2,5,6,7]|7[0,1,7,8]|8[0-9]|9[1,8,9])\d{8}$/;
      // console.log('value', value)
      if (value === '') {
        callback(new Error('请输入手机号码'))
      } else if (!reg.test(value)) {
        callback(new Error('请输入正确的手机号码'))
      } else {
        callback()
      }
    }
    return {
      subLoading: false,
      visible: false,
      form: {
        account: '',
        email: '',
        password: '',
        repassword: '',
        account_type: '',
        telephone: '',
        uid: null,
        valicode: null
      },
      rules: {
        username: [
          { required: true, message: '请输入已注册帐号', trigger: 'change' }
        ],
        password: [
          { validator: validatePass1, trigger: 'change' }
        ],
        repassword: [
          { validator: validatePass2, trigger: 'change' }
        ],
        telephone: [
          { validator: validatePhone, trigger: 'change' }
        ],
        valicode: [
          { required: true, message: '请输入手机验证码', trigger: 'change' }
        ]
      },
      sendTips: '获取验证码',
      phoneLoading: false, // 手机验证码按钮加载动画
      disabled: false, // 获取验证码按钮是否可按
      //验证码专用
      identifyCodes: '1234567890',
      identifyCode: '',
      code: ''//text框输入的验证码
      //验证码专用结束
    }
  },
  computed: {},
  watch: {},
  created () {},
  mounted () {
    this.identifyCode = '';
    this.makeCode(this.identifyCodes, 4);
  },

  methods: {
    // 修改密码
    handleUpdate () {
      // eslint-disable-next-line consistent-this
      const that = this
      that.$refs.form.validate((valid) => {
        if (valid) {
          if (this.code === '') {
            this.$message.error('请输入图片中的验证码')
            return
          }

          if (this.identifyCode !== this.code) {
            //重置图片验证码
            this.code = ''
            this.refreshCode()
            this.$alert('请正确输入图片中的验证码', '系统消息', {
              confirmButtonText: '确定',
              type: 'error'
            });
            return
          }
          // 如果没有获取验证码
          if (that.sendTips === '获取验证码' && getCookie('sentPhoneNologin') === undefined) {
            that.$message({
              message: '请获取手机验证码',
              type: 'error',
              duration: 5 * 1000
            })
            return 0
          }
          if (!that.form.valicode) {
            that.$message({
              message: '请输入手机验证码',
              type: 'error',
              duration: 5 * 1000
            })
            return
          }
          that.subLoading = true

          const data = {
            'username': that.form.username,
            'password': that.form.password,
            'telephone': that.form.telephone,
            'valicode': that.form.valicode
          }

          /* 发起请求 */
          modifyPassword({
            data
          }).then(res => {
            that.$alert('修改密码成功，点击确定跳转至登录界面。', '系统消息', {
              confirmButtonText: '确定',
              callback: action => {
                that.$router.push('/signin')
              }
            })
            removeCookie('sentPhoneNologin')

            that.disabled = false

          }).catch((res) => {
            that.$message({
              message: res,
              type: 'error',
              duration: 5 * 1000
            })
            console.log('error', res)
          }).finally(() => {
            //重置图片验证码
            this.code = ''
            this.refreshCode()
            that.subLoading = false
          })
        }
      })
    },

    getPhoneCode () {
      if (!this.form.telephone) {
        this.$message.error('请输入手机号码')
        return 0
      }
      if (!isPoneAvailable(this.form.telephone)) {
        this.$message.error('请输入正确的手机号码')
        return 0
      }
      // eslint-disable-next-line consistent-this
      const that = this
      that.phoneLoading = true
      that.disabled = true
      if (that.sendTips !== '获取验证码') {
        return 0
      }
      let time = 60

      sendValidateCode({
        telephone: this.form.telephone,
        type: 2
      }).then(res => {
        that.$message({
          message: res.data.message || 'Success',
          type: 'success',
          duration: 5 * 1000
        })

        setCookie('sentPhoneNologin', true)
        that.phoneLoading = false


        // eslint-disable-next-line
        that.sendTips = time + '秒'

        const timer = setInterval (() => {
          time--
          // eslint-disable-next-line
          that.sendTips = time + '秒'

          if (time === 0) {
            time = 60
            clearInterval(timer)
            that.sendTips = '获取验证码'
            that.disabled = false
          }
        }, 1000)
      }).catch((res) => {
        that.$alert(res, '系统消息');
        that.disabled = false
        that.phoneLoading = false
      })
    },

    //验证码专有代码
    //验证码
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },

    refreshCode () {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes [
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
    }
    //验证码专有代码结束
  }
}
</script>

<style scoped>
p{
  margin: 0;
}

.clear {
  clear: both;
}

.page-title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  padding: 25px 0px;
}

.box{
  height: 385px;
  /* border-radius: 15px; */
  /* border:1px solid #babbbd; */
  margin: 0 auto 20px auto;
  /* box-shadow: #eee 1px 2px; */
}
.common-applicants__edit >>> .el-dialog {
  width: 500px;
  max-width: 90%;
}
.input-with-select >>> .el-input-group__prepend {
  background-color: #fff;
}

.clickTipBtn {
  margin-left:16px;
  width: 108px;
  font-size: 13px;
}

.width260{
  width:260px;
}

.el-form {
  width: 380px;
  margin: 30px auto;
}

.el-form-item{
  margin-bottom: 25px;
  float: right;
}

.el-input{
  width:360px;
}

.xinghao {
  color:red;
  display: inline-block;
  margin: 12px 10px 0 0;
}

.labelleft {
  float: left;
  width: 15px;
}

.width385 {
  width: 385px;
}

.login-button {
  display: inline-block;
  margin-right: 18px;
}

.phoneInput {
  width: 215px;
}

.getCodeBtn {
  margin: 0 0 0 5px;
  float: right;
  width: 140px;
}

.el-form-item__error {
  left: 20px !important;
}

.code {
  width: 228px;
}

/* .el-input__inner {
  height: 30px;
  line-height: 30px;
} */

/* .el-button{
  height: 41px;
  padding: 0 15px;
} */

/*验证码样式*/
.code{
  width:227px;
}
.login-code{
  cursor: pointer;
}

.forgetBtn {
  float: right;
}

.forgetBtn:hover {
  color: #409EFF;
  cursor: pointer;
}

@media screen and (max-width: 405px) {
    .el-form {
      width: 342px;
      margin: 0 auto;
    }
    .el-input {
      width: 325px;
    }
    .phoneInput {
      width: 198px;
      float: left;
    }
    .getCodeBtn {
      width: 120px;
    }
    .input-box {
      width: 350px;
    }
    .code {
      width: 190px;
    }
}


</style>
